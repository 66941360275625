:root { 
--light_blue_700_02:#0b92ce; 
--gray_400:#c4c4c4; 
--black_900_01:#000022; 
--deep_orange_A200:#e86e39; 
--deep_orange_A200_01:#e96e39; 
--deep_orange_A200_7f:#e96e397f; 
--deep_orange_A200_19:#e86e3919;
--light_blue_700_01:#0092d1; 
--blue_gray_100:#d9d9d9; 
--black_900:#000000; 
--teal_800:#23645f; 
--gray_800:#4d4d4d; 
--light_blue_700:#0c92ce; 
--red_400:#e36138; 
--white_A700:#ffffff; 
--light_orange: #E86E391A;
}